<template>
  <div
    class="error"
    :style="{
      backgroundImage: `url(${require('@/assets/img/404-bg.jpg')})`
    }"
  >
    <y-line class="error__line" position="1" />
    <y-line class="error__line" position="3" />
    <y-line class="error__line" position="6" />
    <y-line class="error__line" position="8" />

    <div class="error__content">
      <div class="error__content-error-text">ERROR</div>
      <div class="error__content-error-code">404</div>
      <div class="error__content-error-desc">
        Sorry, the page you were<br/>looking for could not be found.
      </div>
      <btn
        @click="$router.push('/')"
        class="error__content-btn"
        type="mint-white"
        text="Home page"
      />
    </div>
  </div>
</template>

<script>
import YLine from '@/components/common/y-line.vue'
export default {
  name: '404',
  components: {
    YLine,
  },
}
</script>

<style lang="scss" scoped>

.error {
  padding: 50px 15px;

  display: flex;
  justify-content: center;
  align-items: center;

  background: center center no-repeat;
  background-size: cover;


  text-align: center;
  color: #fff;
}

.error__line {
  opacity: .25;
}

.error__content {

}

.error__content-error-text {
  margin-bottom: -45px;
  padding-right: 34px;
  text-align: right;
  font-size: 26px;
}

.error__content-error-code {
  font: 800 306px / 1 Raleway;
}
@supports (-webkit-background-clip: text) {
  .error__content-error-code {
    background: linear-gradient(-125deg, #fff 50%, #FFBE11 50%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
}

.error__content-error-desc {
  text-transform: uppercase;
}

.error__content-btn {
  margin-top: 25px;
}

@media screen and (max-width: $bp_mb) {
  .error__content-error-text {
    margin-bottom: -10px;
    padding-right: 0;
  }
  .error__content-error-code {
    font-size: 180px;
  }
}

@media screen and (max-width: 380px) {
  .error__content-error-code {
    font-size: 150px;
  }
}

</style>

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "error",
      style: {
        backgroundImage: `url(${require("@/assets/img/404-bg.jpg")})`,
      },
    },
    [
      _c("y-line", { staticClass: "error__line", attrs: { position: "1" } }),
      _c("y-line", { staticClass: "error__line", attrs: { position: "3" } }),
      _c("y-line", { staticClass: "error__line", attrs: { position: "6" } }),
      _c("y-line", { staticClass: "error__line", attrs: { position: "8" } }),
      _c(
        "div",
        { staticClass: "error__content" },
        [
          _c("div", { staticClass: "error__content-error-text" }, [
            _vm._v("ERROR"),
          ]),
          _c("div", { staticClass: "error__content-error-code" }, [
            _vm._v("404"),
          ]),
          _vm._m(0),
          _c("btn", {
            staticClass: "error__content-btn",
            attrs: { type: "mint-white", text: "Home page" },
            on: {
              click: function ($event) {
                return _vm.$router.push("/")
              },
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "error__content-error-desc" }, [
      _vm._v(" Sorry, the page you were"),
      _c("br"),
      _vm._v("looking for could not be found. "),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }